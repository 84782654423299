var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _vm.fieldsLoading
        ? _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            _vm._l(9, function (n) {
              return _c(
                "v-col",
                { key: n, attrs: { cols: "12", sm: "6", lg: "3" } },
                [
                  _c(
                    "v-sheet",
                    { staticClass: "mt-2" },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : [
            _vm.customModuleObj.projectId
              ? _c("h4", { staticClass: "pa-1 pl-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("message.projects.project")) +
                      ": " +
                      _vm._s(_vm.printProjectName)
                  ),
                ])
              : _vm._e(),
            _c(
              "form-data",
              {
                ref: "customModuleReference",
                attrs: { references: _vm.formReferences, model: _vm.modelObj },
                on: {
                  "update:references": function ($event) {
                    _vm.formReferences = $event
                  },
                },
              },
              [
                _vm.modelObj.id && _vm.listOfDocuments.length > 0
                  ? _c(
                      "template",
                      { slot: "custom_modules_documents" },
                      [
                        _c("documents", {
                          attrs: {
                            documents: _vm.listOfDocuments,
                            deleteIds: _vm.deleteDocumentIds,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }